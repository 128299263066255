import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AcademicDiscipline, Area, cities, CompanyInformation, Country, Currency, DocumentsList, JobPost, JobRole, ListValuesMap, Qualificationa, Skills, Specialization, SpecialQualificationa, State, UserType } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import * as _moment from 'moment';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { POstJobList, POstJobSpecialization } from '../post-a-job.component';
const moment = _moment;

// export class POstJobSpecialization {
//     SpecializationId: number[];
//     PostJobId: number;
//   }

//   export class POstJobList {
//     JobCompanyName: string;
//    Id: number;
//     SpecializationDescription: string
//     PostJobId: number;
//   }

@Component({
    selector: 'app-edit-job-post',
    templateUrl: './edit-job-post.component.html',
    styleUrls: ['./edit-job-post.component.scss']
})
export class EditJobPostComponent implements OnInit, OnDestroy {
    @ViewChild('select') select: MatSelect;
    submittedJob: boolean = false;
    jobPostForm: FormGroup;
    public jobPostInfo = [];
    response: any;
    showMe: boolean = false;
    displayMe: boolean = false;
    hideMe: boolean = false;
    appearMe: boolean = false;
    allCities: Observable<cities[]>;
    allCountries: Observable<Country[]>
    allJobRole: Observable<JobRole[]>;
    allareas: Observable<Area[]>;
    allQualification?: Observable<Qualificationa[]>;
    allspecialqualification?: Observable<SpecialQualificationa[]>;
    allDocuments?: Observable<DocumentsList[]>;
    allSkills?: Observable<Skills[]>;
    allCurrency?: Observable<Currency[]>;
    allGender?: Observable<any[]>;
    allJobType?: Observable<any[]>;
    allTypeofjob?: Observable<any[]>;
    allSalarytype?: Observable<any[]>;
    allExperienceType?: Observable<any[]>;
    allWorkingDays?: Observable<any[]>;
    allDutyType?: Observable<any[]>;
    allStates: Observable<State[]>;
    allJobLocationType: Observable<any[]>;
    allworkinghpurs: Observable<any[]>;
    allShiftType?: Observable<any[]>;
    allNoticePeriod?: Observable<any[]>;
    alldiscipline: Observable<AcademicDiscipline[]>;
    allspecialization: Observable<POstJobList[]>;
    allCompanyName: Observable<CompanyInformation>;
    allJobstatus: Observable<any[]>;
    usertype: number;
    intershiMe: boolean = true;
    stipend: boolean = false;
    salary: boolean = false;
    selectedStatus: number;
    companydata: CompanyInformation;
    Company: number;
    CompanyAddress1: string = '';
    JobDataSource?: JobPost;
    SpecializationDataSource?: any;
    cityId: Number;
    value: number;
    selectCompanyID: number;
    salarytype: boolean = false;
    allrecruiter: Observable<CompanyInformation>;
    allSelected = false;
    specializationsId: number[] = [];
    specializationlist: POstJobList[];
    sendspcId: POstJobSpecialization;
    selectedspec: number[] = [];

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private service: SharedService<any>,
        private router: Router,
        private Global: GlobalService,
    ) { }

    ngOnInit(): void {
        this.jobPostForm = this.formBuilder.group({
            Id: ['', Validators.nullValidator],
            CompanyId: ['', Validators.nullValidator],
            JobCompanyName: ['', Validators.nullValidator],
            JobRoleId: ['', Validators.required],
            JobLocation: ['', Validators.nullValidator],
            JobLocationCity: ['', Validators.nullValidator],
            JobType: ['', Validators.required],
            CurrencyId: ['', Validators.required],
            MinSalary: ['', Validators.nullValidator],
            MaxSalary: ['', Validators.nullValidator],
            JobOpening: ['', Validators.required],
            ReqQualification: ['', Validators.nullValidator],
            Qualification: ['', Validators.nullValidator],
            ExperienceType: ['', Validators.nullValidator],
            ExperienceTypeName: ['', Validators.nullValidator],
            JobShift: ['', Validators.nullValidator],
            JobShiftName: ['', Validators.nullValidator],
            MinYearExp: ['', Validators.nullValidator],
            SkillId: ['', Validators.nullValidator],
            Gender: ['', Validators.nullValidator],
            JobDescription: ['', Validators.required],
            Country: ['', [Validators.required]],
            State: ['', [Validators.required]],
            City: ['', [Validators.nullValidator]],
            WorkingDays: ['', Validators.nullValidator],
            CompanyAddress: ['', Validators.required],
            Typeofjob: ['', Validators.nullValidator],
            SubTypeofJob: ['', Validators.nullValidator],
            DutyType: ['', Validators.nullValidator],
            DutyTypeName: ['', Validators.nullValidator],
            NoticePeriod: ['', Validators.nullValidator],
            NoticePerioddays: ['', Validators.nullValidator],
            Timing: ['', Validators.nullValidator],
            JobTiming: ['', Validators.nullValidator],
            JobLocationType: ['', Validators.nullValidator],
            IsInternShip: ['', Validators.nullValidator],
            AcademicDisciplineId: ['', Validators.required],
            SpecializationId: ['', Validators.required],
            Jobstatus: ['', Validators.required],
            Salarytype: ['', Validators.nullValidator],
            TargetDate: ['', Validators.required],
            RecruiterId: ['', Validators.nullValidator],
            JobPostspecializationId: ['', Validators.nullValidator],
            JobSpecification: ['', Validators.nullValidator],
            JobFor: ['', Validators.nullValidator],
        })

        this.GetEmployeeDataById(this.Global.getjobTypeId());
        this.allCities = this.service.Get('City/GetCity?Id=0', null);
        this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);
        this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
        this.allCurrency = this.service.Get('Currency/GetCurrency?Id=0', null);
        this.allSkills = this.service.Get('Skills/GetSkills?Id=0', null);
        this.allDocuments = this.service.Get('Document/GetDocument?Id=0', null);
        this.allGender = this.service.Get('GetListValue?Id=' + ListValuesMap.Gender, null);
        this.allSalarytype = this.service.Get('GetListValue?Id=' + ListValuesMap.Salarytype, null);
        this.allJobType = this.service.Get('GetListValue?Id=' + ListValuesMap.JobType, null);
        this.allTypeofjob = this.service.Get('GetListValue?Id=' + ListValuesMap.TypeOfJob, null);
        this.allworkinghpurs = this.service.Get('GetListValue?Id=' + ListValuesMap.JobWokingHours, null);
        this.allNoticePeriod = this.service.Get('GetListValue?Id=' + ListValuesMap.NoticePeriods, null);
        this.allDutyType = this.service.Get('GetListValue?Id=' + ListValuesMap.DutyType, null);
        this.allExperienceType = this.service.Get('GetListValue?Id=' + ListValuesMap.ExperienceType, null);
        this.allShiftType = this.service.Get('GetListValue?Id=' + ListValuesMap.ShiftType, null);
        this.allWorkingDays = this.service.Get('GetListValue?Id=' + ListValuesMap.WorkingDays, null);
        this.allCountries = this.service.Get('Country/GetCountry?Id=0', null);
        this.allStates = this.service.Get('State/GetStateData?Id=0', null);
        this.allJobLocationType = this.service.Get('/GetListValue?Id=' + ListValuesMap.JobLocationType, null);
        this.selectedStatus = 3;
        this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineData?Id=0', null);
        this.allCompanyName = this.service.Get('Company/GetCompanyDataSelection', null);
        this.allrecruiter = this.service.Get('Recruiter/GetActiveRecruiterData', null);
        this.allJobstatus = this.service.Get('/GetListValue?Id=' + ListValuesMap.Jobstatus, null);
        this.usertype = this.Global.GetUserType();
        if(this.companydata)
        {
            this.companydata[0].Address1;
        }
       
        this.sendspcId = new POstJobSpecialization();
    }

    get fjob(): { [key: string]: AbstractControl } {
        return this.jobPostForm.controls;
    }

    GetEmployeeDataById(Id: any) {
        this.service.Get('Job/GetJobPostData?Id=' + Id, null).subscribe(data => {
            this.JobDataSource = data;
            this.jobPostForm.controls["Id"].setValue(this.JobDataSource[0].Id);
            this.jobPostForm.controls["JobCompanyName"].setValue(this.JobDataSource[0].JobCompanyName);
            this.jobPostForm.controls["CompanyAddress"].setValue(this.JobDataSource[0].CompanyAddress);
            this.jobPostForm.controls["WorkingDays"].setValue(this.JobDataSource[0].WorkingDays);
            this.jobPostForm.controls["Timing"].setValue(this.JobDataSource[0].Timing);
            this.jobPostForm.controls["State"].setValue(this.JobDataSource[0].State);
            this.jobPostForm.controls["Country"].setValue(this.JobDataSource[0].Country);
            this.jobPostForm.controls["JobRoleId"].setValue(this.JobDataSource[0].JobRoleId);
            this.jobPostForm.controls["JobLocation"].setValue(this.JobDataSource[0].JobLocation);
            this.jobPostForm.controls["JobType"].setValue(this.JobDataSource[0].JobType);
            this.jobPostForm.controls["Typeofjob"].setValue(this.JobDataSource[0].Typeofjob);
            this.jobPostForm.controls["ExperienceType"].setValue(this.JobDataSource[0].ExperienceType);
            if (this.JobDataSource[0].ExperienceType == 0) {
                this.appearMe = false;
            }
            else {
                this.appearMe = true;
            }
            this.jobPostForm.controls["JobShift"].setValue(this.JobDataSource[0].JobShift);
            this.jobPostForm.controls["MinYearExp"].setValue(this.JobDataSource[0].MinYearExp);
            this.jobPostForm.controls["CurrencyId"].setValue(this.JobDataSource[0].CurrencyId);
            this.jobPostForm.controls["MinSalary"].setValue(this.JobDataSource[0].MinSalary);
            this.jobPostForm.controls["MaxSalary"].setValue(this.JobDataSource[0].MaxSalary);
            this.jobPostForm.controls["JobOpening"].setValue(this.JobDataSource[0].JobOpening);
            this.jobPostForm.controls["ReqQualification"].setValue(this.JobDataSource[0].ReqQualification);
            this.jobPostForm.controls["Gender"].setValue(this.JobDataSource[0].Gender);
            this.jobPostForm.controls["Salarytype"].setValue(this.JobDataSource[0].Salarytype);
            if (this.JobDataSource[0].Salarytype == 0) {
                this.salarytype = false;
            }
            else {
                this.salarytype = true;
            }
            this.jobPostForm.controls["JobDescription"].setValue(this.JobDataSource[0].JobDescription);
            this.jobPostForm.controls["JobLocationType"].setValue(this.JobDataSource[0].JobLocationType);
            this.jobPostForm.controls["IsInternShip"].setValue(this.JobDataSource[0].IsInternShip);
            this.jobPostForm.controls["DutyType"].setValue(this.JobDataSource[0].DutyType);
            this.jobPostForm.controls["AcademicDisciplineId"].setValue(this.JobDataSource[0].AcademicDisciplineId);
            this.jobPostForm.controls["NoticePeriod"].setValue(this.JobDataSource[0].NoticePeriod);
            this.jobPostForm.controls["CompanyId"].setValue(this.JobDataSource[0].CompanyId);
            this.jobPostForm.controls["Jobstatus"].setValue(this.JobDataSource[0].Jobstatus);
            this.jobPostForm.controls["RecruiterId"].setValue(this.JobDataSource[0].RecruiterId);
            this.jobPostForm.controls["JobSpecification"].setValue(this.JobDataSource[0].JobSpecification);
            const momentDate1 = new Date(this.JobDataSource[0].TargetDate);
            const formateddate1 = moment(momentDate1).format("yyyy-MM-DD");
            this.JobDataSource[0].TargetDate = formateddate1;
            this.jobPostForm.controls["TargetDate"].setValue(this.JobDataSource[0].TargetDate);
            this.selectCompanyID = this.JobDataSource[0].CompanyId;
            if (this.JobDataSource[0].JobLocationType == 0) {
                this.showMe = this.JobDataSource[0].JobLocation;
            }
            else if (this.JobDataSource[0].JobLocationType == 1) {
                this.displayMe = this.JobDataSource[0].JobLocation;
            }
            else {
                this.hideMe = this.JobDataSource[0].JobLocation;
            }
            if (this.JobDataSource[0].IsInternShip == true) {
                this.intershiMe = false;
                this.stipend = true;
                this.salary = false;
            }
            if (this.JobDataSource[0].IsInternShip == false) {
                this.intershiMe = true;
                this.stipend = false;
                this.salary = true;
            }
            this.GetSpecialization(this.JobDataSource[0].Id)
            this.onchangeDiscipline(this.JobDataSource[0].AcademicDisciplineId)
        })
    }

    GetSpecialization(Id: any) {
        this.service.Get('Job/GetPostedJobSpecialization?Id=' + Id, null).subscribe(data => {
            this.SpecializationDataSource = data;
            for (let item of this.SpecializationDataSource) {
                this.specializationsId.push(item.SpecializationId);
            }
            this.jobPostForm.controls["SpecializationId"].setValue(this.specializationsId);
        })
    }

    updatePostInfoForm() {
        this.submittedJob = true;
        if (this.jobPostForm.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            this.allspecialization.subscribe((value: POstJobList[]) => {
                this.specializationlist = value;
                if (this.allSelected) {
                    for (var index in this.specializationlist) {
                        this.specializationsId.push(this.specializationlist[index].Id);
                    }
                    this.jobPostForm.controls["CompanyId"].setValue(this.selectCompanyID);
                    const Job = this.jobPostForm.getRawValue();
                   
                    Job.SpecializationId = this.specializationsId;
                    this.service.Put('Job/UpdateJobPostData', Job).subscribe(
                        (res) => {
                            this.toastr.success(res);
                            this.router.navigate(['companymanagejob']);
                        },
                        (error) => {
                            this.toastr.error(error.error);
                        });
                    this.submittedJob = false;
                }
                else {
                    this.jobPostForm.controls["CompanyId"].setValue(this.selectCompanyID);
                    const Job = this.jobPostForm.getRawValue();
                   
                    Job.SpecializationId = this.specializationsId;
                    this.service.Put('Job/UpdateJobPostData', Job).subscribe(
                        (res) => {
                            this.toastr.success(res);
                            this.router.navigate(['companymanagejob']);
                        },
                        (error) => {
                            this.toastr.error(error.error);
                        });
                    this.submittedJob = false;
                }
            });
        }
    }

    onchangeCountry(event) {
        this.allStates = this.service.Get('State/GetStateByCountry?countryId=' + event, null);
    }

    onchangeCity(event) {
        this.allCities = this.service.Get('City/GetCityDataByState?StateId=' + event, null);
    }

    onchangesalary(event) {
        if (event == 0) {
            this.salarytype = false;
        }
        else {
            this.salarytype = true;
        }
    }

    onchangeQualification(Qualification: any) {
        this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + Qualification, null);
    }

    onchangeDiscipline(event) {
        this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + event, null);
    }

    onchangecompany(event) 
    {
        this.allCompanyName.subscribe((data) => {
            const company = data.find((item) => item.CompanyName === event)
            this.jobPostForm.controls["CompanyAddress"].setValue(company.Address1 + ' ' + company.Address2);
            this.selectCompanyID = company.Id;
        })
    }

    toggleTag() 
    {
        this.showMe = true;
        this.displayMe = false;
        this.hideMe = false;
    }

    toggleTag2()
     {
        this.appearMe = true;
    }

    toggleTag3() {
        this.appearMe = false;
    }

    Tag() {
        this.displayMe = true;
        this.showMe = false;
        this.hideMe = false;
    }

    Tag1() {
        this.hideMe = true;
        this.showMe = false;
        this.displayMe = false;
    }

    intership() {
        this.intershiMe = false;
        this.stipend = true;
        this.salary = false;
    }

    intership1() {
        this.intershiMe = true;
        this.stipend = false;
        this.salary = true;
    }

    expType(event: any) {
        if (event == 0) {
            this.appearMe = false;
        }
        else {
            this.appearMe = true;
        }
    }

    toggleAllSelection() {
        if (this.allSelected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    getValues(event: {
        isUserInput: any;
        source: { value: any; selected: any };
    }) {
        if (event.isUserInput) {

            if (event.source.selected === true) {
                this.specializationsId.push(event.source.value);
            } else {
                var index = this.specializationsId.indexOf(event.source.value);
                if (index !== -1) {
                    this.specializationsId.splice(index, 1);
                }
            }
        }
    }

    ngOnDestroy(): void {

    }
}
