import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService,
        private router: Router,
        private toastr: ToastrService){}

        canActivate(
          next: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): boolean | UrlTree {
             let url: string = state.url;
       
                 return this.checkLogin(url);
             }
       
             checkLogin(url: string): true | UrlTree {
                console.log("Url: " + url)
                let val: string = localStorage.getItem('isUserLoggedIn');
       
                if(val != null && val == "true"){
                   if(url == "/login")
                      this.router.parseUrl('/expenses');
                   else 
                      return true;
                } else {
                   return this.router.parseUrl('/login');
                }
             }

}
