<div class="bottom-item">
  
    <div class="create-button">
        <div class="frame-1170">
            <div class="education">View Resume <i matTooltip="Close" style="font-size: 35px;margin-left: 660px;margin-top: -15px;color: red;"  
                class='bx bx-x Clear' (click)="close()"></i></div>
            <div class="add-education"></div>
        </div>
    </div>
    <h4>{{filepath}}</h4>

    <div id="outerContainer" style="width: 827px;">
      <iframe  [src]="safeUrl" width="100%" height="600px"></iframe>
    </div>
</div> 
